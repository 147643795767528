.ml2 h1 {
  font-weight: 900;
  font-size: 3.5em;
  color: aqua;
}

.ml2 .letter {
  display: inline-block;
  line-height: 1em;
}

.splash-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.splash-item1-1 {
  flex: 1;

}

.fullscreen-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.fullscreen-bg__img {
  position: absolute;
  /* top: 50%;
  left: 50%; */
  transform: translate(-40%, -40%);
  min-width: 20%;
  min-height: 20%;
  width: auto;
  height: auto;
  z-index: -1;
  animation: zoomin 5s forwards;
}

@keyframes zoomin {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(2);
  }
}

/* Ensure the main container takes up the full height of the viewport */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* The App container */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes at least the full height of the viewport */
}

/* Main content container should grow to fill available space */
#gallery-container {
  flex: 1; /* This allows the gallery container to take up all available space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  padding: 20px;
}

/* Footer should stay at the bottom */
footer {
  background-color: #f5f5f5;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
}
