 @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Quando&display=swap');


 /* DELETE IMAGE ADMIN */

 .Delimages-container {
     max-width: 1200px;
     margin: 2rem auto;
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
     gap: 1rem;
 }

 .delimage {
     width: 100%;
     cursor: pointer;
     margin-bottom: 5rem;
 }

 .delimage img {
     width: 100%;
     height: 100%;
     border-radius: 8px;


     aspect-ratio: 3/2;
     object-fit: cover;
     transition: transform 0.3s ease;
 }

 .delimage:hover img {
     opacity: 0.4;
 }


 .category-container {
     margin-bottom: 20px;
     /* transition: all 3s; */

 }

 .category-container h2 {
     margin-bottom: 10px;
     font-family: "Comfortaa", sans-serif;
     font-optical-sizing: auto;
     font-weight: 700;
     font-style: normal;
 }



 .category-container .gallery-container {
     display: flex;
     overflow: hidden;
 }

 .category-container .gallery-container img {
     width: calc(100% / 1);
     /* Adjust the number of images per row */
     height: 300px;
     /* width: 300px; */
     /* Adjust the height of the images */
     object-fit: cover;
     margin-right: 15px;
     /* Adjust the spacing between images */
 }



 @media (max-width: 768px) {
     .category-container .gallery-container img {
         width: calc(100% / 1);

         height: 200px;

         object-fit: cover;
         margin-right: 15px;

     }
 }


 /* Disable right-click on images */
.gallery-image {
    pointer-events: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    filter: blur(0); /* Ensures no visual change */
}

/* Prevent image dragging */
.gallery-image {
    -webkit-touch-callout: none; /* Prevent callout to copy image, etc when tap to hold */
    -webkit-user-select: none;   /* Disable selection/copy in UIWebView */
    -moz-user-select: none;      /* Disable selection/copy in Firefox */
    -ms-user-select: none;       /* Disable selection/copy in IE */
    user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
    pointer-events: none;        /* Disable click events */
}

