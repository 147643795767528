@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Belleza&family=Dancing+Script:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Belleza&family=Dancing+Script:wght@600&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap');

#h55 {
    font-family: "Zen Maru Gothic", serif;
    font-size: 18px;
    margin-bottom: 0;
    padding-top: 10px;
}

.h33 {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    margin-top: 0;

}

/* .ragooty-photograher {
    line-height: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
} */

h5 {
    font-family: 'Belleza', sans-serif;
    font-weight: 600;
    font-size: 18px;
    /* background: linear-gradient(to right, #101010 0, #fff 10%, #161616 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-decoration: none;
    white-space: nowrap; */

}

#dehebtn {
    cursor: pointer;
    line-height: 0.9em;
}

.dd_header {
    font-family: 'Belleza', sans-serif;
    font-weight: 600;
    font-size: 38px;
    background: linear-gradient(to right, #101010 0, #fff 10%, #161616 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-decoration: none;
    white-space: nowrap;
}

p,
a {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;


}

.eventvenue {
    font-size: 13px;
    font-weight: 300;
    /* background-color: #bacdd4bf;
    border-radius: 18%;
     */


}

.expandable-content a {
    text-decoration: none;
    color: inherit;
    background-color: red;

}

/* .expandable-content {
    line-height: 10%;
} */


.circle-image {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;

}




/* #event { */

/* Your existing styles */
/* transition: height 0.1s ease-in-out; */
/* Smooth transition for height */
/* overflow: hidden; */
/* Hide overflow content when collapsed */

/* } */

/* .expanded {
    height: auto;
} */


.card {
    width: fit-content;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    gap: 20px;
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055); */
    border: none;
}

/* for all social containers*/
.socialContainer {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition-duration: .3s;
}

/* instagram*/
.containerOne:hover {
    background-color: #d62976;
    transition-duration: .3s;
}

/* twitter*/
.containerTwo:hover {
    background-color: #00acee;
    transition-duration: .3s;
}

/* linkdin*/
.containerThree:hover {
    background-color: #0538e1;
    transition-duration: .3s;
}

/* Whatsapp*/
.containerFour:hover {
    background-color: #128C7E;
    transition-duration: .3s;
}

.socialContainer:active {
    transform: scale(0.9);
    transition-duration: .3s;
}

.socialSvg {
    width: 17px;
}

.socialSvg path {
    fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
    animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


.button1 {
    color: #fff;
    padding: 0.7em 1.7em;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    border-radius: 0.5em;
    background: rgb(44, 44, 44);
    ;
    cursor: pointer;
    border: 1px solid #e8e8e8;
    transition: all 0.3s;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
    width: 80vw !important;
    max-width: 400px;
}

.button1:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #000000, inset -4px -4px 12px #000000;
}











.btn-shine {


    padding: 12px 48px;
    color: #fff;
    background: linear-gradient(to right, #9f9f9f 0, #fff 10%, #868686 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    white-space: nowrap;
    font-family: "Poppins", sans-serif;
}


/* .copyright {
    position: absolute;
    top: 97%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: small;
    font-family: "Montserrat", sans-serif;
    opacity: 50%;
} */

@-moz-keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 280px;
    }
}

@-webkit-keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 280px;
    }
}

@-o-keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 280px;
    }
}

@keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 280px;
    }
}




.usa-grid {
    background-color: transparent;
    z-index: 2;
    width: 75vw !important;
    max-width: 400px;
    border-width: 2px;
    border-color: #000000;
    border-style: solid;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
    padding: 5px 15px;
    color: #000000;
    border-radius: 8px;
}

#cheese {
    margin: auto;
    width: 100%;
    font-family: source-sans-pro, sans-serif;
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 5px;

}

#cheese h1 {
    margin-bottom: 0px;
}

.title {
    font-size: .5em;
    /* Reduce by 1/4 from 5.5em */
    text-transform: uppercase;
}

#cheese {
    font-size: 0.6em;
    /* Reduce by 1/4 from 2em */
    background-color: rgb(44, 44, 44);
    margin-top: -2.5%;
    width: 25%;
    /* padding: 20px; */
    color: #fff;
}

#cheese1 {
    font-family: "Montserrat", sans-serif;
    font-size: 8px;
    /* Reduce by 1/4 from 2em */
    /* background-color: rgb(44, 44, 44); */
    margin-top: -2.5%;
    width: 25%;
    color: rgb(0, 0, 0);
    padding-bottom: 5px;
    width: 100%;
    text-align: center;
}


#sauce {
    /* margin-top: -25px; */
    font-size: 12px;
    margin-bottom: 0px;
}

#sauce1 {
    font-size: 8px;
    margin-bottom: 13px;
}

/* #crust {
    font-size: .7em;
    background-color: rgb(44, 44, 44);

    width: 25%;
    color: white;
    
    width: 100%;
    text-align: center;

} */

/* h1:nth-of-type(2) {
    padding-top: 25px;
} */