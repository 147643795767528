body {
    background-color: rgb(255, 255, 255);
    margin: 18px;
}

.lg-react-element {
    column-count: 3;
    column-gap: 0px;

} 

@media (max-width: 768px) {
    .lg-react-element {
        column-count: 1;
    }
}

img {
    max-width: 100%;
    display: block;
    padding: 5px 5px;
    border-radius: 2px;
}

