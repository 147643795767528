@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Belleza&family=Dancing+Script:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Belleza&family=Dancing+Script:wght@600&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap');


.deheedeham-container {
    height: 100vh;
    /* Set the container height to full viewport height */
    overflow-y: auto;
    /* Allow vertical scrolling if content exceeds viewport height */
}


h1 {
    font-family: "Zen Maru Gothic", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    margin-bottom: 0px;
}

h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;

}

h4 {
    font-family: "Zen Maru Gothic", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
}

h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600 !important;
    font-size: 18px;
    /* background: linear-gradient(to right, #101010 0, #fff 10%, #161616 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-decoration: none;
    white-space: nowrap; */

}

.dd_header {
    font-family: "Zen Maru Gothic", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    background: linear-gradient(to right, #101010 0, #fff 10%, #161616 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-decoration: none;
    white-space: nowrap;
}

p {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;


}

.par {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 300px; */
    padding-top: 20px;
}


/* 
.bookbutton {
    width: 200px;
    height: 200px;
    object-fit: fill;
}

.bookbutton img {
    object-fit: cover;
} */





.btn-shine {


    padding: 12px 48px;
    color: #fff;
    background: linear-gradient(to right, #9f9f9f 0, #fff 10%, #868686 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    white-space: nowrap;
    font-family: "Poppins", sans-serif;
}


@-moz-keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 280px;
    }
}

@-webkit-keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 280px;
    }
}

@-o-keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 280px;
    }
}

@keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 280px;
    }
}